.editor-wrapper {
  min-height: 400px;
  height: fit-content;
  margin: 24px;
  border: none;
}

.popup-wrapper {
  display: inline;
  position: relative;
}
.popup-selected-table {
  position: absolute;
  left: 0;
  background-color: white;
  padding: 6px 10px;
  border: 1px solid lightgray;
  height: fit-content;
  z-index: 1;
}
