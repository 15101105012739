/* Style the table header */
.custom-table .ant-table-thead > tr > th {
  background-color: #1890ff !important; /* Dark gray */
  color: white; /* White text */
  font-weight: bold; /* Bold text for headers */
}

/* Add border-radius to the first and last header cells */
.custom-table .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 32px;
}

.custom-table .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 32px;
}

.custom-table .ant-table-header {
  background-color: #1890ff; /* Dark gray background */
  border-radius: 32px; /* Add border radius to the header */
}
