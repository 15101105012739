#parking-event > .ant-badge-status-dot {
  border: 1px solid #6ce7ba;
  box-shadow: inset 0px 0px 0px 3px rgb(255 255 255);
  width: 15px !important;
  height: 15px !important;
}

#speed-event > .ant-badge-status-dot {
  border: 1px solid #ff609a;
  box-shadow: inset 0px 0px 0px 3px rgb(255 255 255);
  width: 15px !important;
  height: 15px !important;
}

#incident-panel > .ant-collapse-header {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  text-align: center;
}

.ant-collapse-header #incident_dashboard_table {
  border: none !important;
}

#incident_dashboard_table
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead {
  background: #3f87e4 !important;
}

.incident_dashboard_table_event_row {
  background: #e7eff378 !important;
}

#incident_dashboard_table
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  color: white !important;
  text-align: center;
}

#incident_dashboard_table > .ant-table-content > .ant-table-body {
  margin: 0px !important;
  background: white !important;
}

#incident-card
  > div
  > div.ant-progress-outer
  > div.ant-progress-inner
  > .ant-progress-success-bg {
  background: #ff609a !important;
}

.incident-card {
  background: #f5f6ff;
  border-radius: 10px;
  border: 1px dashed #a499ff;
  box-shadow: -3px 0px 0px 0px #adb3ff, 3px 0px 0px 0px #adb3ff;
  z-index: 1;
  margin: -33px !important;
}

.total-incident-event {
  font-size: 20px;
  font-weight: bold;
  color: #2f70ef;
}

.subtotal-incident-event {
  font-size: 14px;
  font-weight: bold;
}

.tree {
  margin: -25px;
  padding: 0;
}
.tree:not(:empty):before,
.tree:not(:empty):after,
.tree ul:not(:empty):before,
.tree ul:not(:empty):after,
.tree li:not(:empty):before,
.tree li:not(:empty):after {
  display: block;
  position: absolute;
  content: "";
}
.tree ul,
.tree li {
  position: relative;
  margin: 0;
  padding: 0;
}
.tree li {
  list-style: none;
}
.tree li > div {
  color: #5c5c5c;
  padding: 15px;
  display: inline-block;
  width: 180px;
  text-align: center;
}

.tree.vertical {
  display: flex;
  justify-content: space-around;
  margin-top: 45px;
}
.tree.vertical ul {
  display: flex;
  justify-content: center;
}
.tree.vertical li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tree.vertical li div {
  margin: 12px 134px;
}
.tree.vertical li:before {
  border-left: 1px dashed #a499ff;
  height: 12px;
  width: 0;
  top: 0;
}
.tree.vertical li:after {
  border-top: 1px dashed #a499ff;
  height: 0;
  width: 100%;
}
.tree.vertical li:first-child:after {
  border-top: 1px dashed #a499ff;
  height: 0;
  width: 50%;
  left: 50%;
}
.tree.vertical li:last-child:after {
  border-top: 1px dashed #a499ff;
  height: 0;
  width: 50%;
  right: 50%;
}
.tree.vertical li:only-child:after {
  content: none;
}
.tree.vertical li ul:before {
  border-left: 1px dashed #a499ff;
  height: 12px;
  width: 0;
  top: -12px;
}
.tree.vertical > li:only-child:before,
.tree.vertical > li:only-child:after {
  content: none;
}
