.cpf-dashboard .row-select-style {
  display: flex;
  align-items: center;
}
.cpf-dashboard .select-font-style {
  font-size: 12px;
}
.cpf-dashboard .panel-style {
  margin-bottom: 20px;
  background-color: #3f87e4;
  color: #fff;
  border-radius: 5px;
}
.cpf-dashboard .div-company-style {
  margin: 10px;
}
.cpf-dashboard .div-transfer-style {
  border: 1px dashed black;
  background-color: #dee2e6;
  margin: 10px;
}
.cpf-dashboard .col-panel-style {
  margin: 5px;
}
.cpf-dashboard .panel-font-style {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
.cpf-dashboard .panel-tag-style {
  width: 100%;
  text-align: center;
}
