.monitor-gps-drawer-component .button-search-style {
  margin-left: 5px;
}
.monitor-gps .table-log .ant-table-fixed > colgroup > col:not(:first-child) {
  min-width: 50px !important;
}
.monitor-gps .table-log .ant-table-thead {
  font-size: 11px;
}
.monitor-gps .table-log .ant-table-fixed .ant-table-tbody .ant-table-row {
  font-size: 11px !important;
}

.monitor-gps .table-log .ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.monitor-gps .line-chart-detail {
  height: 300px;
  width: 100%;
  margin-top: 20px;
}

.monitor-gps .title-line-chart {
  font-size: 16px;
}
.monitor-gps .custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px 10px 10px 10px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.monitor-gps .legend-line-chart {
  font-size: 13px;
  background-color: white;
  padding: 10px;
  border: 1px solid black;
  position: absolute;
  /* top: 0px; */
  right: 0px;
  z-index: 1;
}
.monitor-gps .div-legend-line-chart {
  height: 25px;
}
.monitor-gps .div-title-line-chart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 14px;
}

.monitor-gps .line-chart-detail .recharts-yAxis .recharts-label {
  text-anchor: middle !important;
  font-size: 16px !important;
  fill: #000000a6 !important;
}
.monitor-gps .line-chart-detail {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.monitor-gps .line-chart-detail .recharts-wrapper {
  font-size: 12px;
}
