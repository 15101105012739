/* Style only the sorter icons in the table with class 'custom-table' */
.report-custom-table .ant-table-column-sorters {
  font-size: 16px; /* Adjust the size of the sorters */
  color: gray !important; /* Change the color of the sorters */
}

.report-custom-table .ant-table-column-sorter-up,
.report-custom-table .ant-table-column-sorter-down {
  font-size: 18px; /* Increase the font size of the up/down arrows */
  color: gray !important; /* Use a custom color for the up/down arrows */
}

/* Optional: Change sorter arrow colors on hover */
.report-custom-table .ant-table-column-sorter-up:hover,
.report-custom-table .ant-table-column-sorter-down:hover {
  color: gray !important;
}
