.toolbar-richtext {
  background: #ffffff;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  row-gap: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.toolbar-grp > * {
  margin-right: 10px;
  cursor: pointer;
}
.toolbar-grp {
  margin: 0 10px;
}
