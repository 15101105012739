
.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}
  
  /* .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  } */
  
  /* .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  } */