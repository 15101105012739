body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.shake-vertical {
	-webkit-animation: shadow-drop-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: shadow-drop-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-center {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
#parking-event > .ant-badge-status-dot {
  border: 1px solid #6ce7ba;
  box-shadow: inset 0px 0px 0px 3px rgb(255 255 255);
  width: 15px !important;
  height: 15px !important;
}

#speed-event > .ant-badge-status-dot {
  border: 1px solid #ff609a;
  box-shadow: inset 0px 0px 0px 3px rgb(255 255 255);
  width: 15px !important;
  height: 15px !important;
}

#incident-panel > .ant-collapse-header {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  text-align: center;
}

.ant-collapse-header #incident_dashboard_table {
  border: none !important;
}

#incident_dashboard_table
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead {
  background: #3f87e4 !important;
}

.incident_dashboard_table_event_row {
  background: #e7eff378 !important;
}

#incident_dashboard_table
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  color: white !important;
  text-align: center;
}

#incident_dashboard_table > .ant-table-content > .ant-table-body {
  margin: 0px !important;
  background: white !important;
}

#incident-card
  > div
  > div.ant-progress-outer
  > div.ant-progress-inner
  > .ant-progress-success-bg {
  background: #ff609a !important;
}

.incident-card {
  background: #f5f6ff;
  border-radius: 10px;
  border: 1px dashed #a499ff;
  box-shadow: -3px 0px 0px 0px #adb3ff, 3px 0px 0px 0px #adb3ff;
  z-index: 1;
  margin: -33px !important;
}

.total-incident-event {
  font-size: 20px;
  font-weight: bold;
  color: #2f70ef;
}

.subtotal-incident-event {
  font-size: 14px;
  font-weight: bold;
}

.tree {
  margin: -25px;
  padding: 0;
}
.tree:not(:empty):before,
.tree:not(:empty):after,
.tree ul:not(:empty):before,
.tree ul:not(:empty):after,
.tree li:not(:empty):before,
.tree li:not(:empty):after {
  display: block;
  position: absolute;
  content: "";
}
.tree ul,
.tree li {
  position: relative;
  margin: 0;
  padding: 0;
}
.tree li {
  list-style: none;
}
.tree li > div {
  color: #5c5c5c;
  padding: 15px;
  display: inline-block;
  width: 180px;
  text-align: center;
}

.tree.vertical {
  display: flex;
  justify-content: space-around;
  margin-top: 45px;
}
.tree.vertical ul {
  display: flex;
  justify-content: center;
}
.tree.vertical li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tree.vertical li div {
  margin: 12px 134px;
}
.tree.vertical li:before {
  border-left: 1px dashed #a499ff;
  height: 12px;
  width: 0;
  top: 0;
}
.tree.vertical li:after {
  border-top: 1px dashed #a499ff;
  height: 0;
  width: 100%;
}
.tree.vertical li:first-child:after {
  border-top: 1px dashed #a499ff;
  height: 0;
  width: 50%;
  left: 50%;
}
.tree.vertical li:last-child:after {
  border-top: 1px dashed #a499ff;
  height: 0;
  width: 50%;
  right: 50%;
}
.tree.vertical li:only-child:after {
  content: none;
}
.tree.vertical li ul:before {
  border-left: 1px dashed #a499ff;
  height: 12px;
  width: 0;
  top: -12px;
}
.tree.vertical > li:only-child:before,
.tree.vertical > li:only-child:after {
  content: none;
}

.scroll-style::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-top-right-radius: 70px;
  border-bottom-right-radius: 70px;
}

.scroll-style::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.scroll-style::-webkit-scrollbar-thumb {
  background-color: #535353;
  border: 1px solid #555555;
  border-radius: 20px;
}

.popup-menu-custom {
  max-height: 400px;
  min-width: 200px;
  overflow: auto;
}

.popup-menu-custom-set-width {
  min-width: 200px;
}

th.column-money,
td.column-money {
  text-align: right !important;
  margin-right: 30px;
}

th.column-text-center,
td.column-text-center {
  text-align: center !important;
}

@-webkit-keyframes invalid {
  from {
    background-color: red;
  }

  to {
    background-color: inherit;
  }
}

@keyframes invalid {
  from {
    background-color: red;
  }

  to {
    background-color: inherit;
  }
}

.invalid {
  -webkit-animation: invalid 1s infinite;
  /* Safari 4+ */
  /* Fx 5+ */
  /* Opera 12+ */
  animation: invalid 1s infinite;
  /* IE 10+ */
}

/** custom antd **/
.ant-collapse-content-box {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ant-collapse-header {
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

.ant-card {
  line-height: 1.3 !important;
}

.ant-card-meta-title {
  font-size: 13px !important;
}

.ant-card-body {
  padding: 10px 8px 10px 8px !important;
}

.ant-card-actions {
  line-height: 0 !important;
}

.ant-card-actions li {
  height: 0.3px !important;
}

.ant-card-actions li span {
  line-height: 0 !important;
  font-size: 13px !important;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;

  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.antd-pro-notice-icon-list
  .antd-pro-notice-icon-item
  .antd-pro-notice-icon-iconElement {
  font-size: 22px;
}

.antd-pro-notice-icon-list
  .antd-pro-notice-icon-item
  .antd-pro-notice-icon-title {
  font-size: 12px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.antd-pro-notice-icon-datetime {
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

.ant-table-thead {
  font-size: 11px;
}

.ant-list-item {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.antd-pro-notice-icon-list .antd-pro-notice-icon-item {
  padding: 0px 10px 0px 10px;
}

.ant-form-item-label {
  overflow: unset !important;
  line-height: 28px !important;
  padding: 1px 0px 0px 0px !important;
}

.highlight-row {
  background-color: rgba(179, 224, 255, 0.3);
}

.highlight-row-dark {
  background-color: rgba(179, 224, 255, 0.6);
}

.bg-gradient {
  background: linear-gradient(269deg, #397aff, #6288ff, #b4cdff);
  background-size: 600% 600%;

  -webkit-animation: RowBGGradient 10s ease infinite;
  animation: RowBGGradient 10s ease infinite;
}

@-webkit-keyframes RowBGGradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 51%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes RowBGGradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 51%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.menu-item-wrap {
  white-space: normal !important;
  line-height: 20px !important;
  height: 100% !important;
  /* margin-top: 4px !important; */
  /* margin-bottom: 4px !important; */
  overflow: visible !important;
  display: inline-block !important;
}

.display-menu {
  line-height: 40px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

/* .modal-driver-card:hover {
  opacity: 0.2;
} */

@media only screen and (max-width: 576px) {
  .common-drawer {
    width: 80%;
  }
}

.alert-alarm-table {
  margin: 0px 0px 10px 0px !important;
}

/* .alert-alarm-table div table thead{
  background-color: #dededebf !important;
}

.alert-alarm-table div table thead tr th{
  border-style: none !important;
} */

.alert-alarm-table div table tbody tr td {
  padding: 1px 2px !important;
}

.tracking-table {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.tracking-table div table thead tr th {
  font-size: 9px;
}

.tracking-table div table tbody tr td {
  font-size: 12px;
  padding: 1px 4px !important;
}

.edge-element-tracking {
  height: 35px !important;
  font-size: 0.875rem !important;
}

.noti-center {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.noti-center div table thead tr th {
  font-size: 14px;
  color: white;
  background-color: #3f87e4 !important;
}

.noti-center div table tbody tr td {
  font-size: 14px;
  padding: 5px !important;
}

.tracking-table-v2 {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.tracking-table-v2 div table thead tr th {
  font-size: 0.75rem;
  color: white;
  background-color: #3f87e4 !important;
}

.tracking-table-v2 div table tbody tr td {
  font-size: 10px;
  padding: 5px !important;
}

.current-location-tracking-style {
  /* font-size: 16px !important; */
  margin-right: 4px;
  padding: 4px;
  line-height: 14px;
}

.tracking-detail-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 12px;
}

.tracking-detail-table > tbody > tr > th {
  width: 135px;
  height: 40px;
}

.red-dot {
  height: 0.7vw;
  width: 0.7vw;
  background-color: rgb(255, 0, 0);
  border-radius: 50%;
  display: inline-block;
}

.green-dot {
  height: 0.7vw;
  width: 0.7vw;
  background-color: rgb(0, 133, 0);
  border-radius: 50%;
  display: inline-block;
}

/* .tracking-detail-table {
  border-left: solid #40a9ff 0.5px;
} */

.edge-btn-group button {
  line-height: 1.499;

  font-weight: 400;
  text-align: center;
  touch-action: manipulation;

  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;

  font-size: 14px;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;

  padding: 8px 15px;
  /* Some padding */
  cursor: pointer;
  /* Pointer/hand icon */
  font-weight: 400;
  text-align: center;
  display: block;
  /* Make the buttons appear below each other */
}

.edge-btn-group button:not(:last-child) {
  /* Prevent double borders */
  /* border-bottom: 0; */
}

/* .edge-btn-group button:hover {
  background: #1890ff;
  color: #fff;
} */

/* Add a background color on hover */
.edge-btn-group button:hover,
.edge-btn-group button:focus {
  color: #40a9ff;
  background-color: #fff;
  border-color: #40a9ff;
}

.edge-element {
  /* border-radius: 0 !important; */
}

.table-sign-4 {
  width: 30px;
  height: 30px;
}

.content-bold {
  color: #fd6e00;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
}

.circleBase {
  border-radius: 50%;
}

.yellow-dot-marker {
  width: 6px;
  height: 6px;
  background: #ffd619;
  border: 0.2px solid black;
}

.red-dot-marker {
  width: 6px;
  height: 6px;
  background: #c91c1c;
  border: 0.2px solid black;
}

.type3 {
  width: 500px;
  height: 500px;
  background: aqua;
  border: 30px solid blue;
}

.radio-button-tracking-style {
  width: 100% !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;

  text-align: center !important;
  outline: none !important;
  height: 35px !important;
}

.radio-button-tracking-style:hover {
  color: #40a9ff !important;
  border-bottom-color: #40a9ff !important;
}

.radio-group-button-tracking-style {
  width: 100% !important;
}

.tool-btn-active {
  color: #40a9ff !important;
  background-color: #fff !important;
  border-color: #40a9ff !important;
}

.infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
}

.overlay {
  display: block;
  top: 0px;
  left: 0px;
  opacity: 0.2;
}

.dashcamera-cover-icon {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: grey;
}

.dashcamera-card {
  position: relative;
  text-align: center;
}

.overlay:hover {
  opacity: 1;
}

.dashcamera-card:hover .overlay {
  opacity: 1;
}

.ant-modal-content {
  border-radius: 30px !important;
}

.ant-modal-header {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
}

.snapshot-modal-tracking .ant-modal-content {
  border-radius: 30px !important;
}

/* ///////////////////////////// test for replay ///////////////////////////////// */
.replay-info-box {
  padding: 15px 5px 5px 20px;
}

.replay-monitor {
  position: absolute;
  top: 5px;
  left: 5px;
}

.replay-speed-gauge {
  margin-left: 230px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.monitor {
  /* width: 278px;
  height: 220px; */
  overflow: none;
  border: solid 1em rgb(247, 247, 247);
  border-radius: 0.2em;
}

.monitor::-webkit-scrollbar {
  width: 15px;
}

.tracking-drawer-mask .ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(0 0 0 / 0%) !important;
}

/* .stand:before {
  content: "";
  display: block;
  position: relative;
  background: rgb(214, 214, 214);
  width: 110px;
  height: 50px;
  top: 270px;
  left: 90px;
} */
/* .stand:after {
  content: "";
  display: block;
  position: relative;
  background: #333;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  width: 300px;
  height: 15px;
  top: 50px;
  left: 110px;
} */

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.helper {
  --reactour-accent: #5cb7b7;
  line-height: 1.3;
  color: #2d2323;
}

.custom-tooltip {
  line-height: 0.6;
  /* background-color: white; */
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.custom-tooltip-body {
  font-size: 13px;
  margin: 5px 5px 5px 5px;
}

.font_bg_big {
  font-family: "Proxima Light", sans-serif;
  font-size: 600%;
  color: rgba(100, 100, 100, 0.3);
  overflow: hidden;
  padding: 0;
  margin: 0;
  bottom: -40px;
  right: 0;
  position: absolute;
}

.policy_form_delete_button {
  z-index: 20;
  overflow: hidden;
  padding: 0;
  margin: 0;
  top: 10;
  right: 0;
  position: absolute;
}

.booking-steps-content {
  padding: 0px 0px 0px 15px;
}

.form-divider {
  padding: 10px 0 !important;
  margin: 3px 0 !important;
}

.form-divider::before,
.form-divider::after {
  border-top: 1.4px solid #bdbdbd !important;
}

.form-divider-text {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600 !important;
  font-size: 17px;
  line-height: 1.4;
}

.vis-custom-time {
  background-color: red !important;
}

.vis-item.vis-background {
  background-color: rgba(7, 138, 245, 0.4) !important;
}

.edge-btn-group .disabled-button button {
  background-color: #f1eded !important;
  cursor: not-allowed;
  opacity: 0.9;
}

.blink {
  -webkit-animation: blinker 2s linear infinite;
          animation: blinker 2s linear infinite;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.policy-alert-input > .ant-input-wrapper > input.ant-input {
  background-color: #ff4a4a;
}

.policy-alarm-input > .ant-input-wrapper > input.ant-input {
  background-color: #fdff52;
}

.ant-input.policy-alert-input {
  background-color: #ff4a4a;
}

.ant-input.policy-alarm-input {
  background-color: #fdff52;
}

.ant-input.policy-new-alert-input {
  border-color: #f53c56;
  border: 1px solid #f53c56 !important;
}

.ant-input.policy-new-alarm-input {
  border-color: #ffeb00;
  border: 1px solid #ffeb00 !important;
}

.my-tooltip > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #062e56;
}

.my-tooltip > .tooltip-arrow {
  border-top: 5px solid #062e56;
}

/* Tooltip id=1 */
.tooltip-bottom-green-1 {
  position: relative;
  display: inline-block;
}

.tooltip-bottom-green-1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.tooltip-bottom-green-1 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.tooltip-bottom-green-1:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.tooltip-bottom-yellow-1 {
  position: relative;
  display: inline-block;
}

.tooltip-bottom-yellow-1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.tooltip-bottom-yellow-1 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.tooltip-bottom-yellow-1:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.tooltip-bottom-orange-1 {
  position: relative;
  display: inline-block;
}

.tooltip-bottom-orange-1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.tooltip-bottom-orange-1 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.tooltip-bottom-orange-1:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.tooltip-bottom-rad-1 {
  position: relative;
  display: inline-block;
}

.tooltip-bottom-rad-1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.tooltip-bottom-rad-1 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.tooltip-bottom-rad-1:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/* End Tooltip id=1 */

/* Tooltip id=2 */
.tooltip-bottom-green-2 {
  position: relative;
  display: inline-block;
}

.tooltip-bottom-green-2 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 75%;
  margin-left: -60px;
}

.tooltip-bottom-green-2 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.tooltip-bottom-green-2:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

/* End Tooltip id=2 */

/* Tooltip id=3 */
.tooltip-bottom-green-3 {
  position: relative;
  display: inline-block;
}

.tooltip-bottom-green-3 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 74%;
  margin-left: -60px;
}

.tooltip-bottom-green-3 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.tooltip-bottom-green-3:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

/* Tooltip id=3 */

/* Tooltip id=4 */
.tooltip-bottom-green-4 {
  position: relative;
  display: inline-block;
}

.tooltip-bottom-green-4 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 70%;
  margin-left: -60px;
}

.tooltip-bottom-green-4 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 80%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.tooltip-bottom-green-4:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

/* End tooltip id=4 */

/* Tooltip id=5 */
.tooltip-right-green-5 {
  position: relative;
  display: inline-block;
}

.tooltip-right-green-5 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.tooltip-right-green-5 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2ed877 transparent transparent transparent;
}

.tooltip-right-green-5:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

/* End tooltip id=5 */

/* Tooltip id=6 */
.tooltip-top-yellow-6 {
  position: relative;
  display: inline-block;
}

.tooltip-top-yellow-6 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 122%;
  left: 82%;
  /* left: var(--radius); */
  margin-left: -50px;
}

.tooltip-top-yellow-6 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcc00 transparent transparent transparent;
}

.tooltip-top-yellow-6:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

/* End tooltip id=6 */

/* Tooltip id=7 */
.tooltip-top-yellow-7 {
  position: relative;
  display: inline-block;
}

.tooltip-top-yellow-7 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 122%;
  left: 80%;
  /* left: var(--radius); */
  margin-left: -50px;
}

.tooltip-top-yellow-7 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcc00 transparent transparent transparent;
}

.tooltip-top-yellow-7:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

/* End Tooltip id=7 */

/* Tooltip id=8 */
.tooltip-left-green8 {
  position: relative;
  display: inline-block;
}

.tooltip-left-green8 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 80%;
  left: 70%;
  margin-left: -50px;
}

.tooltip-left-green8 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 85%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2ed877 transparent transparent transparent;
}

.tooltip-left-green8:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

/* End Tooltip id=8 */

/* Tooltip id=9 */
.tooltip-right-yellow9 {
  position: relative;
  display: inline-block;
}

.tooltip-right-yellow9 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 100%;
  margin-left: -50px;
}

.tooltip-right-yellow9 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcc00 transparent transparent transparent;
}

.tooltip-right-yellow9:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

/* End Tooltip id=9 */

/* Tooltip id="10" */
.tooltip-top-orange-10 {
  position: relative;
  display: inline-block;
}

.tooltip-top-orange-10 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 83%;
  /* left: var(--radius); */
  margin-left: -50px;
}

.tooltip-top-orange-10 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ef6628 transparent transparent transparent;
}

.tooltip-top-orange-10:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

/* End Tooltip id=10 */

/* Tooltip id=11 */
.tooltip-top-orange-11 {
  position: relative;
  display: inline-block;
}

.tooltip-top-orange-11 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 80%;
  /* left: var(--radius); */
  margin-left: -50px;
}

.tooltip-top-orange-11 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ef6628 transparent transparent transparent;
}

.tooltip-top-orange-11:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

/* End Tooltip id=11*/

/* Tooltip id=12 */
.tooltip-top-yellow-12 {
  position: relative;
  display: inline-block;
}

.tooltip-top-yellow-12 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 77%;
  /* left: var(--radius); */
  margin-left: -50px;
}

.tooltip-top-yellow-12 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcc00 transparent transparent transparent;
}

.tooltip-top-yellow-12:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

/* End Tooltip id=12 */

/* Tooltip id=13 */
.tooltip-red-13 {
  position: relative;
  display: inline-block;
}

.tooltip-red-13 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 20%;
  margin-left: -50px;
}

.tooltip-red-13 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #e53349 transparent transparent transparent;
}

.tooltip-red-13:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/* End Tooltip id=13 */

/* Tooltip id=14 */
.tooltip-top-green-14 {
  position: relative;
  display: inline-block;
}

.tooltip-top-green-14 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 92%;
  margin-left: -50px;
}

.tooltip-top-green-14 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2ed877 transparent transparent transparent;
}

.tooltip-top-green-14:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

/* End Tooltip id=14 */

/* Tooltip id=15 */
.tooltip-top-green-15 {
  position: relative;
  display: inline-block;
}

.tooltip-top-green-15 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 75%;
  margin-left: -50px;
}

.tooltip-top-green-15 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2ed877 transparent transparent transparent;
}

.tooltip-top-green-15:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

/* End Tooltip id=15 */

/* Tooltip id=16 */
.tooltip-top-green-16 {
  position: relative;
  display: inline-block;
}

.tooltip-top-green-16 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 85%;
  left: 92%;
  margin-left: -50px;
}

.tooltip-top-green-16 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2ed877 transparent transparent transparent;
}

.tooltip-top-green-16:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

/* End Tooltip id=16 */

/* Tooltip id=17 */
.tooltip-top-green-17 {
  position: relative;
  display: inline-block;
}

.tooltip-top-green-17 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 85%;
  left: 75%;
  margin-left: -50px;
}

.tooltip-top-green-17 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2ed877 transparent transparent transparent;
}

.tooltip-top-green-17:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

/* End Tooltip id=17 */

/* Tooltip id=18 */
.tooltip-top-yellow-18 {
  position: relative;
  display: inline-block;
}

.tooltip-top-yellow-18 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 85%;
  /* left: var(--radius); */
  margin-left: -50px;
}

.tooltip-top-yellow-18 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 90%;
  left: 48%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcc00 transparent transparent transparent;
}

.tooltip-top-yellow-18:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

/* End Tooltip id=18 */

/* Tooltip id=19 */
.tooltip-top-yellow-19 {
  position: relative;
  display: inline-block;
}

.tooltip-top-yellow-19 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 80%;
  margin-left: -50px;
}

.tooltip-top-yellow-19 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 48%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcc00 transparent transparent transparent;
}

.tooltip-top-yellow-19:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

/* End Tooltip id=19 */

/* Tooltip id=20 */
.tooltip-top-yellow-20 {
  position: relative;
  display: inline-block;
}

.tooltip-top-yellow-20 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 80%;
  margin-left: -50px;
}

.tooltip-top-yellow-20 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 48%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffcc00 transparent transparent transparent;
}

.tooltip-top-yellow-20:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

/* End Tooltip id=20 */

/*New Tooltip id=1 */
.new-tooltip-grey-1 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-grey-1 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-1:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-1 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-green-1 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-1:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-1 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-yellow-1 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-1:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-1 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-orange-1 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-1:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-1 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-1 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-rad-1 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-1:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=1 */

/*New Tooltip id=2 */
.new-tooltip-grey-2 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-2 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-grey-2 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-2:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-2 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-2 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-green-2 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-2:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-2 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-2 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-yellow-2 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-2:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-2 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-2 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-orange-2 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-2:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-2 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-2 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-rad-2 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-2:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=2 */

/* New Tooltip id=3 */
.new-tooltip-grey-3 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-3 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 80%;
  margin-left: -60px;
}

.new-tooltip-grey-3 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-3:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-3 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-3 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 80%;
  margin-left: -60px;
}

.new-tooltip-green-3 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-3:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-3 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-3 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-yellow-3 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-3:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-2 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-3 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-orange-3 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-3:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-3 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-3 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 90%;
  margin-left: -60px;
}

.new-tooltip-rad-3 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 40%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-3:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=3 */

/*New Tooltip id=4 */
.new-tooltip-grey-4 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-4 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 95%;
  margin-left: -75px;
}

.new-tooltip-grey-4 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-4:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-4 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-4 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 95%;
  margin-left: -75px;
}

.new-tooltip-green-4 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-4:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-4 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-4 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-4 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-4:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-4 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-4 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-4 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-4:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-4 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-4 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-4 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-4:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=4 */

/* New Tooltip id=5 */
.new-tooltip-grey-5 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-5 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-5 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-5:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-5 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-5 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-5 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-5:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-5 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-5 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-5 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-5:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-5 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-5 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-5 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-5:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-5 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-5 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-5 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-5:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=5 */

/*New Tooltip id=6 */
.new-tooltip-grey-6 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-6 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-6 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-6:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-6 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-6 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-6 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-6:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-6 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-6 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-6 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-6:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-6 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-6 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-6 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-6:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-6 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-6 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-6 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-6:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=6 */

/*New Tooltip id=7 */
.new-tooltip-grey-7 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-7 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-7 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-7:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-7 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-7 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-7 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-7:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-7 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-7 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-7 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-7:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-7 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-7 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-7 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-7:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-7 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-7 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-7 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-7:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=7 */

/*New Tooltip id=8 */
.new-tooltip-grey-8 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-8 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-8 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-8:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-8 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-8 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-8 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-8:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-8 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-8 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-8 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-8:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-8 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-8 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-8 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-8:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-8 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-8 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-8 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-8:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=8 */

/*New Tooltip id=9 */
.new-tooltip-grey-9 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-9 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-9 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-9:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-9 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-9 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-9 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-9:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-9 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-9 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-9 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-9:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-9 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-9 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-9 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-9:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-9 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-9 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-9 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-9:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=9 */

/*New Tooltip id=10 */
.new-tooltip-grey-10 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-10 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-10 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-10:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-10 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-10 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-10 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-10:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-10 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-10 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-10 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-10:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-10 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-10 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-10 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-10:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-10 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-10 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-10 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-10:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=10 */

/*New Tooltip id=11 */
.new-tooltip-grey-11 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-11 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-11 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-11:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-11 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-11 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-11 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-11:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-11 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-11 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-11 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-11:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-11 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-11 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-11 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-11:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-11 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-11 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-11 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-11:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=11 */

/*New Tooltip id=12 */
.new-tooltip-grey-12 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-12 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-12 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-12:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-12 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-12 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-12 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-12:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-12 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-12 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-12 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-12:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-12 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-12 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-12 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-12:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-12 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-12 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-12 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-12:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=12 */

/*New Tooltip id=13 */
.new-tooltip-grey-13 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-13 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-13 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-13:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-13 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-13 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-13 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-13:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-13 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-13 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-13 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-13:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-13 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-13 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-13 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-13:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-13 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-13 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-13 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-13:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=13 */

/*New Tooltip id=14 */
.new-tooltip-grey-14 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-14 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-14 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-14:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-14 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-14 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-14 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-14:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-14 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-14 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-14 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-14:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-14 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-14 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-14 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-14:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-14 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-14 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-14 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-14:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=14 */

/*New Tooltip id=15 */
.new-tooltip-grey-15 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-15 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-15 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-15:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-15 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-15 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-15 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-15:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-15 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-15 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-15 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-15:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-15 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-15 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-15 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-15:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-15 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-15 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-15 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-15:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=15 */

/*New Tooltip id=16 */
.new-tooltip-grey-16 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-16 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-16 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-16:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-16 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-16 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-16 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-16:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-16 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-16 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-16 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-16:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-16 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-16 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-16 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-16:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-16 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-16 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-16 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-16:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=16 */

/*New Tooltip id=17 */
.new-tooltip-grey-17 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-17 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-17 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-17:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-17 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-17 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-17 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-17:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-17 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-17 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-17 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-17:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-17 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-17 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-17 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-17:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-17 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-17 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-17 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-17:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=17 */

/*New Tooltip id=18 */
.new-tooltip-grey-18 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-18 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-18 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-18:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-18 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-18 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-18 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-18:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-18 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-18 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-18 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-18:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-18 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-18 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-18 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-18:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-18 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-18 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-18 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-18:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=18 */

/*New Tooltip id=19 */
.new-tooltip-grey-19 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-19 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-19 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-19:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-19 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-19 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-19 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-19:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-19 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-19 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-19 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-19:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-19 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-19 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-19 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-19:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-19 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-19 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-19 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-19:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=19 */

/*End New Tooltip id=20 */
.new-tooltip-grey-20 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-20 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-20 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-20:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-20 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-20 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-20 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-20:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-20 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-20 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-20 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-20:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-20 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-20 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-20 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-20:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-20 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-20 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-20 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-20:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=20 */

/*New Tooltip id=21 */
.new-tooltip-grey-21 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-21 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-21 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-21:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-21 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-21 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-21 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-21:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-21 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-21 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-21 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-21:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-21 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-21 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-21 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-21:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-21 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-21 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-21 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-21:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=21 */

/*End New Tooltip id=22 */
.new-tooltip-grey-22 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-22 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-22 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-22:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-22 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-22 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-22 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-22:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-22 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-22 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-22 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-22:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-22 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-22 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-22 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-22:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-22 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-22 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-22 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-22:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=22 */

/*End New Tooltip id=23 */
.new-tooltip-grey-23 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-23 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-23 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-23:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-23 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-23 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-23 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-23:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-23 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-23 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-23 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-23:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-23 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-23 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-23 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-23:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-23 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-23 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-23 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-23:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=23 */

/*New Tooltip id=24 */
.new-tooltip-grey-24 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-24 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-24 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-24:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-24 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-24 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-24 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-24:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-24 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-24 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-24 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-24:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-24 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-24 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-24 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-24:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-24 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-24 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-24 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-24:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=24 */

/*New Tooltip id=25 */
.new-tooltip-grey-25 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-25 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-25 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-25:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-25 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-25 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-25 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-25:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-25 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-25 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-25 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-25:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-25 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-25 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-25 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-25:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-25 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-25 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-25 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-25:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=25 */

/*End New Tooltip id=26 */
.new-tooltip-grey-26 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-26 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-26 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-26:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-26 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-26 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-26 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-26:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-26 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-26 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-26 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-26:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-26 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-26 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-26 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-26:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-26 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-26 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-26 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-26:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=26 */

/*New Tooltip id=27 */
.new-tooltip-grey-27 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-27 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-27 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-27:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-27 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-27 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-27 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-27:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-27 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-27 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-27 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-27:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-27 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-27 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-27 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-27:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-27 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-27 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-27 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-27:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=27 */

/*New Tooltip id=28 */
.new-tooltip-grey-28 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-28 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-28 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-28:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-28 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-28 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-28 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-28:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-28 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-28 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-28 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-28:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-28 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-28 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-28 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-28:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-28 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-28 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-28 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-28:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=28 */

/*New Tooltip id=29 */
.new-tooltip-grey-29 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-29 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-29 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-29:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-29 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-29 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-29 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-29:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-29 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-29 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-29 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-29:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-29 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-29 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-29 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-29:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-29 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-29 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-29 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-29:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=29 */

/*New Tooltip id=30 */
.new-tooltip-grey-30 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-30 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-30 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-30:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-30 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-30 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-30 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-30:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-30 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-30 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-30 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-30:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-30 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-30 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-30 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-30:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-30 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-30 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-30 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-30:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=30 */

/*New Tooltip id=31 */
.new-tooltip-grey-31 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-31 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-31 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-31:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-31 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-31 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-31 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-31:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-31 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-31 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-31 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-31:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-31 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-31 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-31 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-31:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-31 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-31 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-31 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-31:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=31 */

/*End New Tooltip id=32 */
.new-tooltip-grey-32 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-32 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-32 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-32:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-32 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-32 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-32 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-32:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-32 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-32 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-32 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-32:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-32 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-32 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-32 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-32:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-32 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-32 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-32 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-32:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=32 */

/*New Tooltip id=33 */
.new-tooltip-grey-33 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-33 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-33 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-33:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-33 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-33 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-33 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-33:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-33 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-33 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-33 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-33:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-33 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-33 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-33 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-33:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-33 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-33 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-33 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-33:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=33 */

/*New Tooltip id=34 */
.new-tooltip-grey-34 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-34 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-34 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-34:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-34 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-34 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-34 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-34:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-34 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-34 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-34 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-34:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-34 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-34 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-34 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-34:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-34 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-34 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-34 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-34:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=34 */

/*New Tooltip id=35 */
.new-tooltip-grey-35 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-35 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-35 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-35:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-35 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-35 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-35 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-35:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-35 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-35 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-35 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-35:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-35 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-35 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-35 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-35:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-35 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-35 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-35 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-35:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=35 */

/*End New Tooltip id=36 */
.new-tooltip-grey-36 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-36 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-36 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-36:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-36 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-36 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-36 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-36:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-36 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-36 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-36 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-36:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-36 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-36 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-36 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-36:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-36 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-36 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-36 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-36:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=36 */

/*New Tooltip id=37 */
.new-tooltip-grey-37 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-37 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-37 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-37:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-37 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-37 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-37 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-37:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-37 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-37 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-37 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-37:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-37 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-37 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-37 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-37:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-37 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-37 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-37 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-37:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=37 */

/*New Tooltip id=38 */
.new-tooltip-grey-38 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-38 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-38 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-38:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-38 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-38 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-38 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-38:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-38 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-38 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-38 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-38:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-38 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-38 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-38 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-38:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-38 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-38 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-38 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-38:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=38 */

/*New Tooltip id=39 */
.new-tooltip-grey-39 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-39 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-39 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-39:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-39 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-39 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-39 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-39:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-39 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-39 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-39 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-39:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-39 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-39 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-39 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-39:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-39 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-39 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-39 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-39:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=39 */

/*New Tooltip id=40 */
.new-tooltip-grey-40 {
  position: relative;
  display: inline-block;
}

.new-tooltip-grey-40 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-grey-40 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #c1c1c1 transparent;
}

.new-tooltip-grey-40:hover .tooltiptext {
  visibility: visible;
  background-color: #c1c1c1;
}

.new-tooltip-green-40 {
  position: relative;
  display: inline-block;
}

.new-tooltip-green-40 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-green-40 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #2ed877 transparent;
}

.new-tooltip-green-40:hover .tooltiptext {
  visibility: visible;
  background-color: #2ed877;
}

.new-tooltip-yellow-40 {
  position: relative;
  display: inline-block;
}

.new-tooltip-yellow-40 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-yellow-40 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffcc00 transparent;
}

.new-tooltip-yellow-40:hover .tooltiptext {
  visibility: visible;
  background-color: #ffcc00;
}

.new-tooltip-orange-40 {
  position: relative;
  display: inline-block;
}

.new-tooltip-orange-40 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-orange-40 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ef6628 transparent;
}

.new-tooltip-orange-40:hover .tooltiptext {
  visibility: visible;
  background-color: #ef6628;
}

.new-tooltip-rad-40 {
  position: relative;
  display: inline-block;
}

.new-tooltip-rad-40 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 85%;
  margin-left: -75px;
}

.new-tooltip-rad-40 .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #e53349 transparent;
}

.new-tooltip-rad-40:hover .tooltiptext {
  visibility: visible;
  background-color: #e53349;
}

/*End New Tooltip id=40 */

.compact-page-header {
  padding: 1px 12px !important;
}

.compact-page-header > .ant-page-header-footer {
  margin-top: 0px !important;
}

.compact-page-header
  > .ant-page-header-heading
  > .ant-page-header-heading-title {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.confirm-concrete-job-table {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.confirm-concrete-job-table div table thead tr th {
  font-size: 9px;
  line-height: 1 !important;
}

.confirm-concrete-job-table div table tbody tr {
  line-height: 1 !important;
}

.confirm-concrete-job-table div table tbody tr td {
  font-size: 12px;
  padding: 1px 4px !important;
}

.compact-steps > div > div > .ant-steps-item-content {
  margin-top: 1px !important;
}

.disabled-row {
  background-color: rgba(214, 214, 214, 1);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.3);
}

.tooltip-marker {
  position: relative;
  display: inline-block;

  align-items: center;
  z-index: -2;
  display: inline-flex;
  transform: translate(-50%, -100%);
}

.tooltip-marker .tooltiptext-marker {
  visibility: hidden;
  width: 120px;
  background-color: rgb(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 215%;
  margin-left: -60px;
}

.tooltip-marker .tooltiptext-marker::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip-marker:hover .tooltiptext-marker {
  visibility: visible;
  transition-delay: 1.2s;
}

.row-multi-drop-continues > td {
  border-top: none !important;
  border-bottom: none !important;
}

.row-multi-drop-head > td {
  border-top: 1px solid #e8e8e8;
  /* border-top: none !important; */
  border-bottom: none !important;
}

.react_time_range__time_range_container {
  padding: 30px 0% 0 !important;
  height: 65px !important;
  width: 100% !important;
}

.react_time_range__tick_label {
  font-size: 9px !important;
}

.react_time_range__handle_wrapper {
  cursor: e-resize !important;
}

.drag-handle {
  cursor: n-resize;
}

.trip-time-picker-title-big {
  font-size: 12px;
}

.trip-time-picker-title-small {
  font-size: 10px;
}

.react_time_range__time_range_container {
  height: 30px !important;
  padding: 15px 0px 0px 0px !important;
}

.react_time_range__track,
.react_time_range__rail__outer,
.react_time_range__rail__inner {
  height: 25px !important;
}

.react_time_range__tick_label {
  margin-top: 0px !important;
}

.react_time_range__tick_marker__large {
  margin-top: 0px !important;
}

/* md */
@media (min-width: 768px) {
  .tracking-box-dirver {
    text-align: center;
  }

  .tracking-box-swipe {
    text-align: center;
  }
}

/* lg */
@media (min-width: 992px) {
  .tracking-box-dirver {
    text-align: left;
    margin-top: 0.2rem;
    padding-left: 2rem !important;
  }

  .tracking-box-swipe {
    text-align: left;
    margin-top: 0.5rem;
    padding-left: 2rem !important;
  }
}

.divider_web_content > .ant-divider-horizontal {
  margin: 10px;
}

.button-drawer-popup {
  color: #0077ea !important;
  border-color: #0077ea !important;
}

.button-drawer-popup:hover {
  color: white !important;
  background-color: #0077ea !important;
}

.driver_license_info_box {
  margin-top: 0px;
}

@media screen and (min-width: 1919px) and (max-width: 2000px) {
  .driver_license_info_box {
    margin-top: 0rem;
    /* margin-top: 3.5rem; */
  }
}

.highlight-row-dark {
  background-color: rgba(179, 224, 255, 0.3);
}

.highlight-row {
  background-color: rgba(179, 224, 255, 0.3);
}

div#videopart {
  background-color: "black" !important;
}

.divider-tracking > .ant-divider-horizontal {
  margin: 10px;
  height: "11rem" !important;
  color: "black" !important;
}

.scrollbar-like-mac {
  float: left;
  height: 550px;
  width: 715px;
  overflow-y: scroll;
  padding: 30px;
}

.scrollbar-like-mac-dash-camera-tabs {
  float: left;
  height: 550px;
  width: 100%;
  overflow-y: scroll;
}

.scrollbar-like-mac-tools-replay {
  float: left;
  height: 300px;
  width: "100%";
  overflow-y: scroll;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(110, 110, 110);
}

#style-1::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.scrollbar-like-mac-advance-search {
  float: left;
  height: 520px;
  width: 678px;
  overflow-y: scroll;
}

#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(110, 110, 110);
}

#style-2::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.scrollbar-like-mac-analytic-graph {
  float: left;
  height: 600px;
  width: 730px;
  overflow-y: scroll;
}

@media only screen and (max-width: 1700px) {
  .scrollbar-like-mac-analytic-graph {
    float: left;
    height: 570px;
    width: 631px;
    overflow-y: scroll;
  }

  .scrollbar-like-mac {
    float: left;
    height: 500px;
    width: 600px;
    overflow-y: scroll;
    padding: 30px;
  }
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(110, 110, 110);
}

#style-3::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.scrollbar-like-mac-add-location {
  float: left;
  height: 450px;
  width: 470px;
  padding-right: 10px;
  overflow-y: scroll;
}

.custom-summary-report-detail > .ant-modal-content > .ant-modal-header {
  border: 0px;
}

.hover__video__and__camera {
  color: black;
  cursor: pointer;
}

.hover__video__and__camera:hover {
  color: #4fadf8;
}

.box-moving-replay {
  display: inline-block;
  margin: 15px 5px;
}

.box-replay:before {
  content: "";
  width: 20px;
  height: 20px;
  vertical-align: center;
  margin: 0 4px -3%;
  display: inline-block;
  border-radius: 5px;
}

.legend-moving-replay:before {
  background-color: #00db6e;
}

.legend-stopped-replay:before {
  background-color: #ff5858;
}

.legend-idling-replay:before {
  background-color: #ffba00;
}

.time-chronology {
  margin-top: 2px !important;
  font-size: 0.8vw !important;
  font-weight: 600 !important;
}

.chronology-main-text {
  font-size: 0.9vw !important;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
  /* margin-top: 6px !important;
  margin-left: 40px !important; */
}

.chronology-sub-text {
  font-size: 0.8vw !important;
  font-weight: 400 !important;
  margin-bottom: 0px !important;
  margin-top: 5px !important;
}

.line-step-style-concrete-dashboard-date {
  min-height: 30px;
  /* ความสูงของเส้น */
  height: auto;
  width: 3px;
  /* ความหนาของเส้น */
  border-left: 3px dashed #bab9c1;
  /* สีของเส้น */
  margin-left: 14px;
}

.line-step-style-concrete-dashboard {
  min-height: 70px;
  /* ความสูงของเส้น */
  height: auto;
  width: 3px;
  /* ความหนาของเส้น */
  border-left: 3px dashed #bab9c1;
  /* สีของเส้น */
  margin-left: 14px;
}

.line-step-style-concrete-dashboard-swipe-card {
  min-height: 40px;
  /* ความสูงของเส้น */
  height: auto;
  width: 3px;
  /* ความหนาของเส้น */
  border-left: 3px dashed #bab9c1;
  /* สีของเส้น */
  margin-left: 14px;
}

.line-step-style-concrete-dashboard-moving {
  min-height: 60px;
  /* ความสูงของเส้น */
  height: auto;
  border-left: 3px dashed #bab9c1;
  /* สีของเส้น */
  margin-left: 14px;
}

.line-step-style-concrete-dashboard-sub-moving {
  min-height: 40px;
  /* ความสูงของเส้น */
  height: auto;
  border-left: 3px dashed #bab9c1;
  /* สีของเส้น */
  margin-left: 14px;
}

.line-step-style-concrete-dashboard-sub-moving-test {
  min-height: 25px;
  /* ความสูงของเส้น */
  height: auto;
  border-left: 3px dashed #bab9c1;
  /* สีของเส้น */
  margin-left: 14px;
}

.line-step-style-concrete-dashboard-loading {
  min-height: 120px;
  /* ความสูงของเส้น */
  height: auto;
  width: 3px;
  /* ความหนาของเส้น */
  border-left: 3px dashed #bab9c1;
  /* สีของเส้น */
  margin-left: 14px;
}

.line-step-style-pto {
  min-height: 40px;
  /* ความสูงของเส้น */
  min-height: 40px;
  /* ความสูงของเส้น */
  height: auto;
  width: 3px;
  /* ความหนาของเส้น */
  border-left: 3px dashed #bab9c1;
  /* สีของเส้น */
  margin-left: 14px;
}

.line-step-style-boom-ready {
  min-height: 40px;
  /* ความสูงของเส้น */
}

.line-step-style-concrete-dashboard-loading {
  min-height: 120px;
  /* ความสูงของเส้น */
  height: auto;
  width: 3px;
  /* ความหนาของเส้น */
  border-left: 3px dashed #bab9c1;
  /* สีของเส้น */
  margin-left: 14px;
}

.map-tools-replay-list-style {
  color: #bab9c1;
  fill: #bab9c1;
  padding: 20px 16px;
  cursor: pointer;
}

.map-tools-replay-list-style:hover {
  color: #4fadf8;
  fill: #4fadf8;
}

.marker-my-location-replay {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 20px;
}

.text-marker-my-location-replay {
  position: absolute;
  top: 30%;
  left: 23%;
  transform: translate(-50%, -50%);
}

.select-compare-graph-calibrate-fuel {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.3s ease-in-out;
  border-radius: 8px;
  background-color: white;
}

.select-compare-graph-calibrate-fuel:hover {
  transform: scale(1.1);
}

.ant-select-lg .ant-select-selection--single {
  height: 40px !important;
}

.ant-input-lg {
  height: 40px !important;
}

.camera-zoom-div {
  transition: transform 0.2s;
}

.camera-zoom-div:hover {
  transform: scale(1.1);
}

.video-camera-zoom-div {
  transition: transform 0.2s;
}

.video-camera-zoom-div:hover {
  transform: scale(1.02);
}

.zoom-info-circle-replay {
  font-size: 14px;
}

.zoom-info-circle-replay:hover {
  font-size: 16px;
  color: #3f87e4 !important;
}

.wrap {
  text-align: center;
  position: relative;
}

.links {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.wrap:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  border-top: 3px dashed #bab9c1;
  /* สีของเส้น */
  width: 50%;
  transform: translateY(-50%);
}

.wrap-speed-replay:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 16px;
  width: 50%;
  transform: translateY(-50%);
}

.expandedTableModalSummaryReport {
  background-color: white !important;
}

.replay-table-v2 {
  margin: 0px 2px 5px 0px !important;
  width: auto;
}

.replay-table-v2 div table thead tr th {
  font-size: 0.75rem;
  color: white;
  background-color: #4fadf8 !important;
}

.replay-table-v2 div table tbody tr td {
  font-size: 12px;
  padding: 5px !important;
}

.replay-diable-select-table-moving {
  pointer-events: none;
}

.blinking:not([disabled]) {
  -webkit-animation: blinker 1s linear infinite;
          animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.icon-with-show-tooltip {
  position: relative;
  display: inline-block;
}

.icon-with-show-tooltip-title {
  color: #fff !important;
  background-color: #051734 !important;
  border: 1px solid #051734 !important;
  min-height: 15px !important;
  position: absolute !important;
  z-index: 1 !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  border-radius: 4px !important;
  transform: translate(-50%, calc(-50% - 48px)) !important;
  padding: 5px !important;
}

.icon-with-show-tooltip-title-arrow {
  position: absolute !important;
  z-index: 1 !important;
  border-left: 3px solid transparent !important;
  border-right: 3px solid transparent !important;
  border-top: 5px solid #051734 !important;
  transform: translate(-50%, calc(-50% - 33px)) !important;
}

.icon-with-show-tooltip-image {
  position: relative;
  align-items: center;
  z-index: 1;
  display: inline-flex;
  transform: translate(-50%, -100%);
}

input#file-upload {
  display: none;
}

.custom-file-upload {
  border: 1px dashed #ccc;
  display: inline-block;
  padding: 6px 40px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
}

.entry-selection-red > .ant-select-selection {
  border: 1px solid red !important;
}

.entry-selection-yellow > .ant-select-selection {
  border: 1px solid #ffbf00 !important;
}

.dashboard__adas_selected > .ant-select-selection {
  border-radius: 8px;
  font-size: 13px;
}

.dashboard__adas_range_datepicker > .ant-calendar-picker-input {
  border-radius: 8px;
  font-size: 13px;
}

.font-11 {
  font-size: 11px !important;
}

.inline {
  display: inline;
}

.tracking-marker {
  margin-bottom: 2px;
  font-size: 9px;
}

.dashboardAdasTabs {
  transition: transform 0.2s;
  /* Animation */
}

.dashboardAdasTabs:hover {
  transform: scale(1.1);
}

.download_video_queue_copy:hover {
  transform: scale(1.2);
}

#websocket_download_queue_table_mac > div > div > div.ant-table-header,
#websocket_all_download_queue_table_mac > div > div > div.ant-table-header {
  overflow: overlay !important;
}

#websocket_download_queue_table_mac
  > div
  > div
  > div.ant-table-header
  > table
  > thead
  > tr
  > th.ant-table-selection-column
  > span
  > div
  > span.ant-table-column-title
  > div,
#websocket_download_queue_table_win
  > div
  > div
  > div.ant-table-header
  > table
  > thead
  > tr
  > th.ant-table-selection-column
  > span
  > div
  > span.ant-table-column-title
  > div {
  display: none !important;
}

.toolbar-readonly {
  pointer-events: none;
}

.slate-editor-img {
  resize: both;
  overflow: auto;
  display: block;
  max-width: 100%;
}

.nonedisplaytest {
  display: none;
}

.TabsStylingHomeDashboard > .ant-tabs-bar {
  margin: 0px !important;
}

.TabsStylingHomeDashboard
  > .ant-tabs-bar
  > .ant-tabs-nav-container
  > .ant-tabs-nav-wrap {
  padding-left: 15%; /* Default padding */
}

/* For 1920px screens */
@media (max-width: 1920px) {
  .TabsStylingHomeDashboard
    > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap {
    padding-left: 18%;
  }
}

/* For 1680px screens */
@media (max-width: 1680px) {
  .TabsStylingHomeDashboard
    > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap {
    padding-left: 14%;
  }
}

/* For 1440px screens */
@media (max-width: 1440px) {
  .TabsStylingHomeDashboard
    > .ant-tabs-bar
    > .ant-tabs-nav-container
    > .ant-tabs-nav-wrap {
    padding-left: 9%;
  }
}

.stylingTablistHomeDashboard
  > .ant-list-item-meta
  > .ant-list-item-meta-avatar {
  margin-top: 3px;
}

.hover-link-usergroup-transfer {
  color: red;
  font-size: 18px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.3s ease-in-out;
}

.hover-link-usergroup-transfer:hover {
  color: #4fadf8;
  transform: scale(1.3);
}

.hover-tab-noti-center {
  width: 120px !important;
  height: 40px !important;
  border-radius: 16px !important;
  margin-right: 10 !important;
  cursor: pointer !important;
  font-weight: bold !important;
  transform: scale(1);
  transition: 0.3s ease-in-out;
}

.hover-tab-noti-center:hover {
  transform: scale(1.1);
}

.custom-map-container {
  position: fixed;
  bottom: 8px; /* Distance from the bottom */
  right: 8px; /* Distance from the left */
  width: 800px; /* Adjust width */
  height: 500px; /* Adjust height */
  z-index: 1000; /* Ensure it's above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow */
  border-radius: 8px; /* Optional: Rounded corners */
  overflow: hidden; /* To ensure the close button stays within the boundaries */
  background-color: white; /* Optional: Background color for better visibility */
}

.close-button {
  position: absolute;
  top: 10px; /* Distance from the top */
  right: 10px; /* Distance from the right */
  background: white; /* Red background for the button */
  color: #ff4d4f; /* White text */
  border: none;
  border-radius: 50%; /* Circular shape */
  width: 30px;
  height: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow */
  z-index: 1001; /* Ensure it's above the map */
  transition: transform 0.2s;
}

.close-button:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
}

.richTextButtonInActive {
  width: 30px;
  height: 20px;
  margin: "0 2px";
  background-color: white;
  border: none;
  opacity: 0.5;
  cursor: pointer;
}

.richTextButtonActive {
  width: 30px;
  height: 20px;
  margin: "0 2px";
  background-color: white;
  border: none;
  opacity: 1;
  cursor: pointer;
}

.ListItemMetaLandingPage {
  padding: 16px 8px !important;
  cursor: pointer !important;
}

.toolbar-richtext {
  background: #ffffff;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  grid-row-gap: 5px;
  row-gap: 5px;
  border-bottom: 1px solid #e5e5e5;
}
.toolbar-grp > * {
  margin-right: 10px;
  cursor: pointer;
}
.toolbar-grp {
  margin: 0 10px;
}


.color-options{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}
.clicked{
  border: 1px solid lightgray;
  border-bottom: none;
}
.option,.hexPreview{
  width: 16px;
  height: 16px;
  background-color: #000000;

}
.color-picker form{
  display: flex;
  align-items: center;
  grid-column-gap: 5px;
  -moz-column-gap: 5px;
       column-gap: 5px;
  width: 100%;
}
.color-picker input{
  width: 65%;
  height:1.3em;
  border:1px solid lightgray;
  border-radius: 5px;
  padding-left:5px
}
.color-picker button{
  margin:0;
  padding:0;
  cursor: pointer;
}
.color-picker input:focus{
  outline: none;
}

.editor-wrapper {
  min-height: 400px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 24px;
  border: none;
}

.popup-wrapper {
  display: inline;
  position: relative;
}
.popup-selected-table {
  position: absolute;
  left: 0;
  background-color: white;
  padding: 6px 10px;
  border: 1px solid lightgray;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;
}

.link-richtext-style {
  display: inline;
  position: relative;
}
.link-popup-style {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 6px 10px;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid lightgray;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.element-image{
    width: 100%;
}
.element-image img{
    width: 100%;
    height:100%;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 8px 8px !important;
    }
    
    .ant-table-thead > tr > th {
    background:none !important;
    }

.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}
  
  /* .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  } */
  
  /* .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
  } */
/* progressbar */
.unload-progress {
	position: relative;
}

.alerticon {
	position: absolute;
	left: 29px;
	top: 16px;
}

.progressbar {
	position: absolute;
	left: 2px;
	top: 41px;
	width: 57px;
	height: 6px;
	border: 1px solid;
}

.percentage {
	height: 100%;
	background-color: #ff0000;
}

.alert {
	margin: 0px;
	width: 50px;
	height: 50px;
	float: left;
}
.engine-on {
	background: url('/app-v1-assets/concrete/working_status.png') no-repeat scroll center center transparent;
}
.engine-off {
	background: url('/app-v1-assets/concrete/working_status.png') no-repeat scroll center center transparent;
}
.state0-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll 0px 0px transparent;
}
.state1-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -50px 0px transparent;
}
.state2-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -100px 0px transparent;
}
.state3-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -150px 0px transparent;
}
.state4-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -200px 0px transparent;
}
.state5-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -250px 0px transparent;
}
.state6-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -300px 0px transparent;
}
.state7-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -350px 0px transparent;
}
.state7-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -350px -50px transparent;
}
.state8-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -400px 0px transparent;
}
.state9-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -450px 0px transparent;
}
.state10-yellow {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -500px 0px transparent;
}
.state0-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll 0px -50px transparent;
}
.state1-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -50px -50px transparent;
}
.state2-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -100px -50px transparent;
}
.state3-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -150px -50px transparent;
}
.state4-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -200px -50px transparent;
}
.state5-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -250px -50px transparent;
}
.state6-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -300px -50px transparent;
}
.state7-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -350px -50px transparent;
}
.state8-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -400px -50px transparent;
}
.state9-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -450px -50px transparent;
}
.state10-red {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -500px -50px transparent;
}
.state0-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll 0px -100px transparent;
}
.state1-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -50px -100px transparent;
}
.state2-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -100px -100px transparent;
}
.state3-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -150px -100px transparent;
}
.state4-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -200px -100px transparent;
}
.state5-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -250px -100px transparent;
}
.state6-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -300px -100px transparent;
}
.state7-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -350px -100px transparent;
}
.state8-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -400px -100px transparent;
}
.state9-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -450px -100px transparent;
}
.state10-green {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -500px -100px transparent;
}
.state0-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll 0px -150px transparent;
}
.state1-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -50px -150px transparent;
}
.state2-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -100px -150px transparent;
}
.state3-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -150px -150px transparent;
}
.state4-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -200px -150px transparent;
}
.state5-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -250px -150px transparent;
}
.state6-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -300px -150px transparent;
}
.state7-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -350px -150px transparent;
}
.state8-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -400px -150px transparent;
}
.state9-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -450px -150px transparent;
}
.state10-white {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -500px -150px transparent;
}

.state8-drum_problem {
	background: url('/app-v1-assets/concrete/alert-icon.png') no-repeat scroll -543px -150px transparent;
}

.cpf-dashboard .row-select-style {
  display: flex;
  align-items: center;
}
.cpf-dashboard .select-font-style {
  font-size: 12px;
}
.cpf-dashboard .panel-style {
  margin-bottom: 20px;
  background-color: #3f87e4;
  color: #fff;
  border-radius: 5px;
}
.cpf-dashboard .div-company-style {
  margin: 10px;
}
.cpf-dashboard .div-transfer-style {
  border: 1px dashed black;
  background-color: #dee2e6;
  margin: 10px;
}
.cpf-dashboard .col-panel-style {
  margin: 5px;
}
.cpf-dashboard .panel-font-style {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
.cpf-dashboard .panel-tag-style {
  width: 100%;
  text-align: center;
}

.monitor-sdcard-drawer-component .button-search-style {
  margin-left: 5px;
}
.monitor-sdcard
  .table-log
  .ant-table-fixed
  > colgroup
  > col:nth-child(5)
  .monitor-sdcard
  .table-vdo
  .ant-table-fixed
  > colgroup
  > col:nth-child(5) {
  min-width: 90px !important;
}
.monitor-sdcard .table-log .ant-table-thead,
.monitor-sdcard .table-vdo .ant-table-thead {
  font-size: 11px;
}
.monitor-sdcard .table-log .ant-table-fixed .ant-table-tbody .ant-table-row,
.monitor-sdcard .table-vdo .ant-table-fixed .ant-table-tbody .ant-table-row {
  font-size: 11px !important;
}

.monitor-sdcard .table-log .ant-table-column-sorters,
.monitor-sdcard .table-vdo .ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.monitor-sdcard .title-line-chart {
  font-size: 16px;
}

.monitor-sdcard .title-page {
  font-size: 16px;
}

.monitor-sdcard .custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px 10px 10px 10px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.monitor-sdcard .legend-line-chart {
  font-size: 13px;
  background-color: white;
  padding: 10px;
  border: 1px solid black;
  position: absolute;
  /* top: 0px; */
  right: 0px;
  z-index: 1;
}
.monitor-sdcard .div-legend-line-chart {
  height: 25px;
}
.monitor-sdcard .div-title-line-chart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 14px;
}

.monitor-sdcard .line-chart-detail .recharts-yAxis .recharts-label {
  text-anchor: middle !important;
  font-size: 16px !important;
  fill: #000000a6 !important;
}
.monitor-sdcard .line-chart-detail {
  width: 100%;
  /* min-height: 500px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.monitor-sdcard .line-chart-detail .recharts-wrapper {
  font-size: 12px;
}
.monitor-sdcard .btn-sdcard {
  padding: 0px !important;
}

.camera-status-dashboard-drawer-component .button-search-style {
  margin-left: 5px;
}
.camera-status-dashboard
  .table-log
  .ant-table-fixed
  > colgroup
  > col:not(:first-child) {
  min-width: 70px !important;
}
.camera-status-dashboard .table-log .ant-table-thead {
  font-size: 11px;
}
.camera-status-dashboard
  .table-log
  .ant-table-fixed
  .ant-table-tbody
  .ant-table-row {
  font-size: 11px !important;
}

.camera-status-dashboard .table-log .ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.camera-status-dashboard .title-page {
  font-size: 16px;
}

.camera-status-dashboard .ant-tag {
  margin-right: 0px !important;
}

.monitor-gps-drawer-component .button-search-style {
  margin-left: 5px;
}
.monitor-gps .table-log .ant-table-fixed > colgroup > col:not(:first-child) {
  min-width: 50px !important;
}
.monitor-gps .table-log .ant-table-thead {
  font-size: 11px;
}
.monitor-gps .table-log .ant-table-fixed .ant-table-tbody .ant-table-row {
  font-size: 11px !important;
}

.monitor-gps .table-log .ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.monitor-gps .line-chart-detail {
  height: 300px;
  width: 100%;
  margin-top: 20px;
}

.monitor-gps .title-line-chart {
  font-size: 16px;
}
.monitor-gps .custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px 10px 10px 10px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.monitor-gps .legend-line-chart {
  font-size: 13px;
  background-color: white;
  padding: 10px;
  border: 1px solid black;
  position: absolute;
  /* top: 0px; */
  right: 0px;
  z-index: 1;
}
.monitor-gps .div-legend-line-chart {
  height: 25px;
}
.monitor-gps .div-title-line-chart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 14px;
}

.monitor-gps .line-chart-detail .recharts-yAxis .recharts-label {
  text-anchor: middle !important;
  font-size: 16px !important;
  fill: #000000a6 !important;
}
.monitor-gps .line-chart-detail {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.monitor-gps .line-chart-detail .recharts-wrapper {
  font-size: 12px;
}

.tax-renewal-readiness-drawer-component .button-search-style {
  margin-left: 5px;
}
.tax-renewal-readiness .tax-renewal-table-log .ant-table-body {
  font-size: 12px;
}

/* Style only the sorter icons in the table with class 'custom-table' */
.report-custom-table .ant-table-column-sorters {
  font-size: 16px; /* Adjust the size of the sorters */
  color: gray !important; /* Change the color of the sorters */
}

.report-custom-table .ant-table-column-sorter-up,
.report-custom-table .ant-table-column-sorter-down {
  font-size: 18px; /* Increase the font size of the up/down arrows */
  color: gray !important; /* Use a custom color for the up/down arrows */
}

/* Optional: Change sorter arrow colors on hover */
.report-custom-table .ant-table-column-sorter-up:hover,
.report-custom-table .ant-table-column-sorter-down:hover {
  color: gray !important;
}

/* Style the table header */
.custom-table .ant-table-thead > tr > th {
  background-color: #1890ff !important; /* Dark gray */
  color: white; /* White text */
  font-weight: bold; /* Bold text for headers */
}

/* Add border-radius to the first and last header cells */
.custom-table .ant-table-thead > tr > th:first-child {
  border-top-left-radius: 32px;
}

.custom-table .ant-table-thead > tr > th:last-child {
  border-top-right-radius: 32px;
}

.custom-table .ant-table-header {
  background-color: #1890ff; /* Dark gray background */
  border-radius: 32px; /* Add border radius to the header */
}

