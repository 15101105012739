.camera-status-dashboard-drawer-component .button-search-style {
  margin-left: 5px;
}
.camera-status-dashboard
  .table-log
  .ant-table-fixed
  > colgroup
  > col:not(:first-child) {
  min-width: 70px !important;
}
.camera-status-dashboard .table-log .ant-table-thead {
  font-size: 11px;
}
.camera-status-dashboard
  .table-log
  .ant-table-fixed
  .ant-table-tbody
  .ant-table-row {
  font-size: 11px !important;
}

.camera-status-dashboard .table-log .ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.camera-status-dashboard .title-page {
  font-size: 16px;
}

.camera-status-dashboard .ant-tag {
  margin-right: 0px !important;
}
