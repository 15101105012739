.monitor-sdcard-drawer-component .button-search-style {
  margin-left: 5px;
}
.monitor-sdcard
  .table-log
  .ant-table-fixed
  > colgroup
  > col:nth-child(5)
  .monitor-sdcard
  .table-vdo
  .ant-table-fixed
  > colgroup
  > col:nth-child(5) {
  min-width: 90px !important;
}
.monitor-sdcard .table-log .ant-table-thead,
.monitor-sdcard .table-vdo .ant-table-thead {
  font-size: 11px;
}
.monitor-sdcard .table-log .ant-table-fixed .ant-table-tbody .ant-table-row,
.monitor-sdcard .table-vdo .ant-table-fixed .ant-table-tbody .ant-table-row {
  font-size: 11px !important;
}

.monitor-sdcard .table-log .ant-table-column-sorters,
.monitor-sdcard .table-vdo .ant-table-column-sorters {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.monitor-sdcard .title-line-chart {
  font-size: 16px;
}

.monitor-sdcard .title-page {
  font-size: 16px;
}

.monitor-sdcard .custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 20px 10px 10px 10px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.monitor-sdcard .legend-line-chart {
  font-size: 13px;
  background-color: white;
  padding: 10px;
  border: 1px solid black;
  position: absolute;
  /* top: 0px; */
  right: 0px;
  z-index: 1;
}
.monitor-sdcard .div-legend-line-chart {
  height: 25px;
}
.monitor-sdcard .div-title-line-chart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 14px;
}

.monitor-sdcard .line-chart-detail .recharts-yAxis .recharts-label {
  text-anchor: middle !important;
  font-size: 16px !important;
  fill: #000000a6 !important;
}
.monitor-sdcard .line-chart-detail {
  width: 100%;
  /* min-height: 500px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.monitor-sdcard .line-chart-detail .recharts-wrapper {
  font-size: 12px;
}
.monitor-sdcard .btn-sdcard {
  padding: 0px !important;
}
